div.page#cases {
    height: 900px;

    div#case-list{
        width: 100%;
        overflow-y: hidden;
    }

    div.page-heading {



    }


}


div.page#update-case {
    height: 800px;
}

div.page#create-case {
    height: 800px;
}