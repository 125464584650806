div.page#homepage {
    width: 100%;
    box-sizing: border-box;
    min-height: 900px;
    * {
        box-sizing: border-box;
    }
    section#info {
        min-height: 170px;
        max-height: 600px;
        width: 100%;
        display: flex;
        padding: 10px;
        justify-content: center;
        flex-wrap: wrap;
        margin: 20px 0;

        div.info-box {
            display: block;
            width: 350px;
            height: 150px;
            border: 1px solid black;
            background-color: #42bfdd;
            padding: 20px;
            box-shadow: 0 5px 10px grey;
            margin: 10px 10px 30px 10px;
            position: relative;
            color: white;
            border-radius: 15px;

            img.icon {
                width: 80px;
                height: 80px;
                filter: invert(1);
            }

            div.number-info {
                height: 100%;
                width: 75%;
                float: right;
                padding: 10px 0;
                text-align: center;

                p {
                    margin: 0;
                    padding: 0;
                }

                p.title {
                    height: 35px;
                    font-size: 20px;
                    font-weight: 600;
                }

                p.number {
                    font-weight: 700;
                    font-size: 45px;
                    width: 100%;
                    text-shadow: 0 2px 3px black;
                }
            }
        }

        div.info-box#member {
            img.icon {
                position: absolute;
                left: 20px;
                top: 30px;
            }
        }

        div.info-box#open-case {
            img.icon {
                position: absolute;
                width: 50px;
                height: 50px;
                left: 25px;
                top: 40px;
            }
        }

        div.info-box#closed-case {
            img.icon {
                position: absolute;
                width: 50px;
                height: 50px;
                left: 25px;
                top: 40px;
            }
        }
    }
}

@media (min-width: 200px) {
    div.page#homepage {
        padding: 0 10px;
    }
}

@media (min-width: 600px) {
    div.page#homepage {
        padding: 0 50px;
    }
}

section#data {
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    div.data-container {
        width: 550px;
        max-width: 100%;
        height: 550px;
        background-color: #f0f6f6;
        margin: 0 20px;
        border-radius: 20px;
        box-shadow: 0 5px 15px grey;
        box-sizing: border-box;
        padding: 30px;

        div.heading {
            height: 50px;
            width: 100%;
            margin: 0;
            padding: 10px 0 0 5px;
            position: relative;

            img.icon {
                float: left;
                width: 30px;
                margin: 0 8px 0 3px;
            }

            h1.title {
                position: relative;
                top: 1px;
                margin: 0;
                color: black;
            }
            button.add {
                width: 90px;
                height: 25px;
                position: absolute;
                right: 20px;
                top: 15px;
                background: none;
                border: 1px solid black;
                border-radius: 15px;

                img.add {
                    position: absolute;
                    width: 11px;
                    height: 11px;
                    left: 10px;
                    top: 7px;
                }

                p {
                    position: absolute;
                    bottom: 3px;
                    left: 30px;
                    padding: 0;
                    margin: 0;
                }
            }

            button.add:hover {
                cursor: pointer;
            }
        }

        div.home-case-list {
            position: relative;
            top: 10px;
            height: 85%;

            a.list-item {
                text-decoration: none;
            }
        }
    }

}


@media (min-width: 200px) and (max-width:1355px) {
    section#data {
        height: 1400px;
        div.data-container {
            margin: 10px 20px;
        }
    }
}

@media (min-width: 1356px) {
    div.data-container {
        margin: 0 20px;
    }
}