div.page {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    padding: 50px;
    box-sizing: border-box;
    top: 60px;

    div.page-heading {
        height: 100px;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        padding: 15px;

        h1.page-title {
            margin: 0;
            position: relative;
            top: 20px;
        }

        button.create {
            width: 140px;
            height: 40px;
            position: absolute;
            right: 50px;
            top: 35px;
            background-color: white;
            margin: 0;
            border: 1px solid black;
            border-radius: 20px;
            box-sizing: border-box;


        }

        button.create:hover {
            cursor: pointer;
            filter: brightness(1.3);
        }

        @media (min-width: 200px) {
            button.create {
                top: 40px;
                right: 10px;
                width: 35px;
                height: 35px;
                padding: 0;

                p {display: none;}
                img#add-icon {
                    width: 13px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            }
        }

        @media (min-width: 1025px) {
            button.create {
                width: 140px;

                p {
                    display: block;
                    position: absolute;
                    width: 80px;
                    top: 10px;
                    padding: 0;
                    margin: 0;
                    left: 40px;
                }
    
                img#add-icon {
                    position: absolute;
                    left: 24px;
                    top: 55%;
                    width: 12px;
                }
            }
        }
    }
}
