div.page#privacy {

    margin-bottom: 100px;

    main{
        padding: 20px;
    }
}

div.page#terms {

    margin-bottom: 100px;

    main{
        padding: 20px;
    }
}